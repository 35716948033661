.input-group{
    font-weight: 600;
    font-size: 0.9rem;
    gap:4px;

    display: flex;
    flex-direction: column;

    flex:0 0 33%;

    margin-bottom: 8px;
}

.input-row{
    display: flex;
    gap:4px;
    width: 100%;
}

.card-file-title{
    max-width: calc(100% - 30px);
    flex: 1 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.loading{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: rgba(0,0,0,50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-container{
    display: flex;
    gap:4px;
    width: 100%;
    margin-top: 8px;
    align-items: flex-end;
}

.img-container > div{
    display: flex;
    flex-direction: column;
    width: 33%;
}

.img-descriptor{
    display: flex;
    flex-direction: column;
    padding: 8px;
    height: auto;
    font-size: 0.8rem;
    gap: 4px;
    background-color: rgb(242, 242, 242);
}

.img-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
}