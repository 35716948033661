.header{
    height: var(--app-header-height);
    background-color: var(--trea-brand-color-aqua);
    padding: 0px 24px;

    display:flex;
    justify-content: center;
    align-items: center;
}

.img{
    width: 80px;
    max-height: 80px;
}

.app-title{
    font-size: 1.6rem;
    font-weight: 500;
    flex-grow: 1;

    margin: 0 16px;
}

.userdiv{
    display: flex;
    flex-direction: column;
}

.userdiv > span:first-child{
    font-size: 1rem;
}

.userdiv > span:last-child{
    font-size: 0.8rem;
    color: var(--trea-typography-subheading-grey);
    margin-top: 4px;
}

@media screen and (max-width: 600px) {
    .app-title{ font-size: 0.9rem; }
    .userdiv > span:first-child { font-size: 0.8rem; }
    .userdiv > span:last-child { font-size: 0.6rem; }
}