.main-content{
    /* background-color: #ebebeb; */
    
    display: flex;
    flex-direction: column;

    align-items: center;
}

.main-content > h1{
    margin-top: 0;
    margin-bottom: 0;
}

.status-code{
    font-size: 6rem;
    color:#ccc;
}