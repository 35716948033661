.xero-container{
    padding: 16px;

    font-size: 0.8rem;

    display: flex;

    gap: 16px;

    flex-direction: column;
}

.header{
    font-size: 1.1rem;
    font-weight: 700;
}

.section-container{
    display: flex;
    flex-direction: column;
}

.section-label{
    color: var(--trea-brand-color-pink);
    font-weight: 500;
    margin-bottom: 4px;
}

.section-body{
    background-color: #F1F0F0;

    display: flex;
    flex-direction: column;

    gap: 8px;

    padding: 16px;
}

.option-selection-wrapper{
    display: flex;
    flex-direction: column;
    border:1px solid #404040;
    padding:4px;
    cursor: pointer;
    margin-bottom: 2px;
}

/* Label and info display */
.field-info-div{
    display: flex;
    flex-direction: column;
}

.field-info-div label{
    font-weight: 600;
}

.error-text{
    margin-top: 4px;
    justify-content: center;
    padding:8px 0;
    font-size: 0.8rem;
    border: 1px solid red;
    background-color: #EF9A9A;
}