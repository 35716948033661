.main{
    display: flex;
    flex-direction: column;
    
    width: auto;
    min-height: var(--main-content-height);

    padding: 0 48px;
}

.main > .loader{
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
}

.title-section{
    display: flex;
    gap: 4px;

    margin: 32px 0;
    justify-content: space-between;
}

.title-section > h1{
    margin: 0;
}

.title-section span{
    color:rgb(110, 110, 110);
    font-size: 0.8rem;
}

.title-section > p{
    margin: 0px;
    color:rgb(110, 110, 110);
}

.activity-stat{
    display: flex;
    gap:8px;
    align-self: flex-end;
}

.activity-stat > div{
    display: flex;
    flex-direction: column;
}

.activity-stat > div:first-child{
    align-items: flex-end;
}

.error-wrapper{
    margin-top: 32px;
}