.wrapper{
    width: auto;
    padding: 8px;
    border-radius: 10px;

    display: flex;
    background-color: #f5f8ff;
    gap:8px;
}

.wrapper > img{
    width: 80px;
    height: 80px;
    max-width: 80px;
    max-height: 80px;
    display: block;
    border-radius: 15px;
    flex: 0 0 auto;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrapper > img:hover{
    transform-origin: bottom left;
    transform: scale(3);
    border-radius: 0;
    z-index: 999;
}

.wrapper > div{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    gap:4px;
}

.img-name{
    font-size: 0.9rem;
    font-weight: 600;
    
    text-overflow: ellipsis;
}

.img-size{
    font-size: 0.8rem;
    color: #A6A6A6;
}

.progress-wrapper{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap:2px;
}

.upload-status{
    font-size: 0.75rem;
}