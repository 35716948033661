.main{
    display: flex;
    
    width: 100%;
    min-height: var(--main-content-height);

    gap: 8px;
}

.order-section{
    flex:1 1 auto;
    background-color: white;

    min-height: var(--main-content-height);
    height: 100%;
}

.xero-section{
    flex:0 0 300px;
    background-color: white;
}

.error-wrapper{
    margin-top: 32px;
}

.noplugin-wrapper{
    width: 100%;
    height:100%;

    display: flex;
    flex-direction: column;

    margin-top: 32px;
    align-items: center;
}

.noplugin-wrapper > * {
    margin: 0 16px;
    font-size: 0.8rem;
    text-align: justify;
}

.noplugin-wrapper > header{
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 8px;
}

