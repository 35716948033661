.nav{
    display: flex;
    flex: 0 0 var(--app-nav-height);
    text-decoration: none;
    background-color: var(--trea-brand-color-aqua);

    padding-left: 32px;
}

.nav-item{
    padding: 8px 16px;
    text-decoration: none;
    font-weight: 500;
    color: black;

}

.active, .nav-item:hover{
    background-color: var(--trea-brand-color-pink);
    color:white;
}