.wrapper{
    display: flex;
    flex-direction: column;
    gap:8px;

    width: 500px;
    max-width: 500px;
}

.input-row{
    display: flex;
    gap:4px;
}

.input-field-group{
    display: flex;
    flex-direction: column;
    
    font-weight: 600;
    font-size: 0.9rem;
    gap:4px;
}