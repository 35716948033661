:root{
  --trea-brand-color-aqua: #84DADE;
  --trea-brand-color-pink: #E41071;
  --trea-brand-color-yellow: #FDE90C;
  --trea-brand-color-purple: #5C0F8B;
  --trea-typography-subheading-grey: #444;

  --app-header-height: 80px;
  --app-nav-height: 40px;
  --app-footer-height: 50px;
  --main-content-height: calc(100vh - var(--app-header-height) - var(--app-nav-height) - var(--app-footer-height))
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 1350px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}