.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}

/* mobile version */
.modal {
    position: fixed;
    top: calc((100vh - 758px) / 2);
    left: 15%;
    width: 70%;
    z-index: 100;
    overflow: scroll;

    margin-right: auto;
    margin-left: auto;
    background-color: white;

    max-height: calc(100vh - 64px);
}

.form-wrapper{
    margin: 16px 48px;
    display: flex;
    flex-direction: column;

    gap:16px
}

.input-group{
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 600;
    font-size: 0.9rem;
}

.actions{
    display: flex;
    gap: 8px;
}

@media (min-width: 768px) {
    .modal {
      left: calc(50% - 15rem);
      width: 30rem;
    }
}