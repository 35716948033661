.wrapper{
    width: 100%;
    height: 96px;
    background-color: white;
    display: flex;

    align-items: center;
    gap: 16px;
}

.img{
    width: 48px;
    height: 48px;
    margin-left: 48px;
}

.wrapper > span{
    font-size: 1.8rem;
    font-weight: 300;

}

.separator{
    height: 5px;
    background-color: red;
    width: 100%;
}