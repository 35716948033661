.main{
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.header{
    background-color: var(--trea-brand-color-aqua);
    height: 95px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.section{
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.form{
    width: 220px;
}

.hr {
    border: 0;
    width: 1px;
    background-color: #7B7B7B;
    height: 300px;
    margin: 40px;
}

.img{
    min-width: 150px;
    min-height:150px;
    width: 150px;
}