.main{
    display: flex;
    
    width: 100%;
    min-height: var(--main-content-height);
    max-height: var(--main-content-height);

    overflow: scroll;
}

.page {
    flex-direction: 'row';
    background-color: '#E4E4E4';
}

.section{
    margin: 10px;
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    background-color: rgb(55, 132, 200);
    font-weight: 800;
}