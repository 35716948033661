.main{
    display: flex;
    flex-direction: column;
    
    width: auto;
    min-height: var(--main-content-height);
    background-color: white;
}

.body-wrapper{
    padding: 16px 48px;
    display: flex;
    flex-direction: column;

    gap:8px;
}

.action{
    display: flex;
}