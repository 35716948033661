.main{
    display: flex;
    flex-direction: column;
    
    width: auto;
    min-height: var(--main-content-height);

    padding: 0 48px;
}

.main > .loader{
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
}

.title-section{
    display: flex;
    flex-direction: column;
    gap: 4px;

    margin: 32px 0;
}

.title-section > h1{
    margin: 0;
}

.title-section > p{
    margin: 0px;
    color:rgb(110, 110, 110);
}