/* Default media query (1200px and up) */
.container{
    display: flex;
    flex-direction: column;

    margin-top: 8px;

    gap: 8px;
}

.sales-rep-wrapper{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.po-wrapper,
.sample-wrapper,
.payment-wrapper{
    display: flex;
    gap:4px;
}

.po-wrapper{
    width: 75%;
}

.sample-wrapper,
.payment-wrapper{
    width: 100%;
}

.payment-wrapper > div:last-child{
    flex: 0 0 50%;
    gap:4px;
}

.sales-rep-wrapper > span,
.po-wrapper span,
.sample-wrapper span,
.payment-wrapper span,
.payment-wrapper > div:last-child span{
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 4px;
}

.txtfield-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media (min-width: 768px) and (max-width: 991px){
    .container{
        background-color: olivedrab;
    }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media (min-width: 992px) and (max-width: 1199px){
    .container{
        background-color: orchid;
    }
}

@media (min-width: 1400px) {
    .container{
        background-color: brown;
    }
} */