.tab-wrapper{
    display: flex;
    flex-direction: column;

    padding: 24px 32px;
}

.tab-wrapper h2{
    margin: 0 0 28px 0;
}

.input-row{
    display: flex;
    gap:8px;
    margin-top: 16px;
}

.input-group{
    display: flex;
    flex-direction: column;
    
    font-weight: 600;
    font-size: 0.9rem;
    color:#626262;
    gap:4px;

    width:280px
}

.action-wrapper{
    background-color: white;
    height: 50px;
    display: flex;
    padding: 0 16px 16px 0;

    justify-content: flex-end;
    margin-bottom: 16px;
}

.xero-button{
    margin-top: 16px;
    border:1px solid #626262;
    border-radius: 4px;
    align-self: flex-start;
    padding: 8px 16px;
    color:#626262;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap:8px;
    cursor: pointer;
}

.xero-button > img{
    width:35px;
    height: 35px;
}

.xero-wrapper{
    display: flex;
    overflow: scroll;
    gap:32px;
}

.xero-body,
.org-data-wrapper{
    display: flex;
    flex-direction: column;
}

.xero-body{gap:16px;}
.org-data-wrapper{
    
    width:350px;

    display: flex;
    flex-direction: row;
    border:1px solid #404040;
    padding:8px;
    cursor: pointer;
}

.org{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-items: flex-start;
    align-content: flex-start;
    gap:8px;
}

.status-chip{
    background-color: #70AD47;
    border-radius: 4px;
    padding:4px 8px;
    font-size: 0.6rem;
    color:white;
}

.org-time{
    color:#404040;
    font-style: italic;
    font-size: 0.7rem;
}