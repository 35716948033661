.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.15);

    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    overflow: hidden;
    width: 30%;
    min-width: 500px;
    max-width: 800px;

    background-color: #fff;

    max-height: calc(100vh - 64px);
    
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header{
    height: 50px;
    background-color: #f5f8ff;
    padding: 0 30px;

    display: flex;
    align-items: center;
}

.header > span{
    color:#929292;
    font-weight: 700;
    flex: 1 1 auto;
}

.content{
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.file-upload-item-container{
    display: flex;
    flex-direction: column;
    gap: 4px;
}