.main{
    display: flex;
    
    width: 100%;
    min-height: var(--main-content-height);
}

.main-section{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin-right: 8px;

    gap: 8px;
}

.activity-section{
    flex:0 1 30%;
    background-color: white;

    min-width: 220px;
    max-width: 300px;

    display: flex;
    flex-direction: column;
    gap: 16px;
    height:auto;
}

.table-section{
    background-color: white;

    flex: 1 1 auto;
    width: 100%;
    text-align: end;

    display: flex;
}

.table-wrapper{
    width: 100%;
    height:100%;
    background-color: white;
    padding: 16px;

    display: flex;
    flex-direction: column;

    gap:8px;
}

.header{
    width: 100%;
    flex: 0 0 60px;

    display: flex;
    align-items: center;
}

.header span{
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0 16px;
}

.header span:first-child{
    flex: 1 1 auto;
}

.date{
    font-weight: 500 !important;
    color: var(--trea-typography-subheading-grey);
    font-size: 1.2rem !important;
}

/* Orders section */
.order-header{
    flex: 1 1 auto;

    color: var(--trea-brand-color-pink);
    font-weight: 500;
    font-size: 1.4rem;
    text-align: start;
}

.order-actions{ flex: 0 0 auto; }

.tab{
    border: 1px solid red;
    
    text-align: start;
}

/* Right Section CSS */
.sales-of-the-month-wrapper{
    flex: 0 0 auto;

    margin: 16px 16px 0 16px;

    display: flex;
    flex-direction: column;
    
    gap:8px;
}

.hr{
    width: auto;
    margin: 0 16px;
}

.activity-wrapper{
    flex: 1 1 auto;
    margin: 0 16px 16px 16px;
    /* max-height: var(--main-content-height); */
    max-height: 100vh;
    height:100%;
    overflow: scroll;
}

.error-banner{
    display: flex;
    border: 1px solid red;
    background-color: #EF9A9A;
    align-items: center;
    padding:8px 0;
    font-size: 0.9rem;
    flex-direction: column;
}