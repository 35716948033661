.container{
    display: flex;
    flex-direction: column;

    gap:8px;
}

.input-group,
.input-group-product{
    font-weight: 600;
    font-size: 0.9rem;
    gap:4px;

    display: flex;
    flex-direction: column;

    margin-bottom: 8px;

    
}

.input-group{
    flex:1 1 100px;
}

.input-row .input-group:nth-child(1){
    flex:1 1 300px;
}

.input-group-product{
    flex:0 0 180px;
}

.input-row{
    display: flex;
    gap:4px;
    width: 100%;
}

.checkbox-wrapper{
    width: 120px;
    align-self: center;
}