.container{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input-row{
    display: flex;

    gap:4px;
}

.input-group-25,
.input-group-75,
.remark-input{
    display: flex;
    flex-direction: column;
    
    font-weight: 600;
    font-size: 0.9rem;
    gap:4px;
}

.input-group-25{
    flex: 0 0 25%;
}

.input-group-75{
    flex: 0 0 75%;
}

.contact-email-wrapper{
    display: flex;
    flex-direction: column;

    flex: 0 0 25%;
    gap:4px;
}

.cost-tabulate-wrapper{
    background-color: lime;
    align-self: flex-end;
    border: 2px solid black;
    
    display: flex;
    flex: 1 1 auto;
}

.cost-tabulate-wrapper > span{
    font-weight: 600;
    font-size: 0.9rem;
    padding: 16px;
}

.cost-tabulate-wrapper > span:nth-child(1){
    border-right: 2px solid black;
    padding-right: 48px;
}

.tabpanel-wrapper{
    display: flex;
    flex-direction: column;

    width:75%;
    
    /* border: 1px solid red; */
}

.add-product-wrapper{
    width: 100%;
    border: 1px dashed green;
    background-color: #E2F0D9;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    padding: 16px 0;
    color: #262626;
}