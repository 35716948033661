.container {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.header {
  display: flex;
  align-items: flex-end;
}

.modified-section {
  display: flex;
  font-size: 0.8rem;
  color: #292929;
  gap: 4px;
}

.modified-section-lbl {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modified-section-user {
  display: flex;
  flex-direction: column;
}

.button-section {
  display: flex;
}

.awaiting-payment-status {
  background-color: #a6d3bb;
  box-shadow: inset 0 0 0 1px #80c19e;
  color: #002e15;
  cursor: pointer;
}

.partial-payment-status {
  background-color: #ffe699;
  box-shadow: inset 0 0 0 1px #bf8f00;
  color: #bf8f00;
  cursor: pointer;
}

.paid-status {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px rgba(0, 10, 30, 0.5);
  color: rgba(0, 10, 30, 0.75);
  cursor: pointer;
}

.overdue-status {
  background-color: #ffcbcb;
  box-shadow: inset 0 0 0 1px #ff2f00;
  color: #ff2f00;
  cursor: pointer;
}

.to-invoice-status {
  background-color: #deebf7;
  box-shadow: inset 0 0 0 1px #4472a9;
  color: #4472a9;
  cursor: pointer;
}

.payment-status-chip {
  white-space: nowrap;
  border-radius: 3px;
  padding: 0 4px;
  text-align: center;
  vertical-align: middle;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  align-self: flex-end;
  margin-left: 12px;
}
