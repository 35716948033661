.main{
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1rem;
    gap:8px;

    
    align-self: center;

    margin-left: auto;
    margin-right: auto;
}