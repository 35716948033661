/* Default media query (1200px and up) */
.container{
    display: flex;
    
    padding: 16px 48px;
    flex-direction: column;

    gap: 16px;
}

.error-banner,
.notification-banner{
    display: flex;
    justify-content: center;
    padding:8px 0;
    font-size: 0.9rem;
}

.error-banner{
    border: 1px solid red;
    background-color: #EF9A9A;
}

.notification-banner{
    border: 1px solid #70AD47;
    background-color: #E2F0D9;
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media (min-width: 768px) and (max-width: 991px){
    .container{
        background-color: olivedrab;
    }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media (min-width: 992px) and (max-width: 1199px){
    .container{
        background-color: orchid;
    }
}

@media (min-width: 1400px) {
    .container{
        background-color: brown;
    }
} */