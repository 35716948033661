.container{
    width: 85%;
    min-width: 530px;
    
    background-color: #FAFAFA;
    border: 1px solid #DDD;
    align-self: center;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap:8px;
}

.form{
    display: flex;
    gap: 4px;

    align-self: center;
    flex-direction: column;
}

.textfield-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:4px;
}

.label{
    font-size: 0.7rem;
    color: #444;
}

.textfield{
    width: 200px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #444;

    font-size: 1.1rem;
}

.date-field{
    width: 150px !important;
}